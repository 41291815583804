import {
  FunctionComponent,
  CSSProperties,
  useEffect,
  useRef,
  useCallback,
} from "react"
import Link from "../../link"
import { LinkProps } from "@reach/router"
import Widget from "."
import WidgetItem, { Props as WidgetItemProps } from "./item"
import { lightTheme, darkTheme } from "../../../services/theme"
import { useStyle } from "../../../hooks/use-style"
import { usePathChecker } from "../../../hooks/use-path"
import React from "react"
import { useUpdate } from "../../../hooks/use-update"

export type Props = {
  title?: string
  borderless?: boolean
  items: {
    [key: string]: WidgetItemProps & {
      to?: string | LinkProps<boolean> | (() => void)
    }
  }
  activeStyle?: CSSProperties
}

const WidgetList: FunctionComponent<Props> = ({
  title,
  items,
  borderless: isBorderless = false,
  activeStyle,
}) => {
  const isPath = usePathChecker()
  const { setSidePanelClosed } = useUpdate()

  const css = useStyle(theme => ({
    borderColor: isBorderless
      ? "transparent"
      : theme === "light"
      ? lightTheme.color.border
      : darkTheme.color.border,
    hoverBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.tertiary
        : darkTheme.color.background.tertiary,
  }))

  const firstUpdate = useRef(true)

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
  }, [])

  const selectItem = useCallback(() => {
    setSidePanelClosed(true)
  }, [setSidePanelClosed])

  const hydrationBugWorkaroundKey = firstUpdate.current ? `client` : `server`

  return (
    <Widget
      title={title}
      borderless={isBorderless}
      tag={Object.values(items).find(item => item.to) ? "nav" : "div"}
    >
      <ul>
        {Object.entries(items).map(([key, item]) => (
          <li key={key + hydrationBugWorkaroundKey} onClick={selectItem}>
            {typeof item.to === "function" ? (
              <button onClick={item.to}>
                <WidgetItem {...item} />
              </button>
            ) : item.to ? (
              <Link
                {...(typeof item.to === "object"
                  ? { to: "/", ref: item.to }
                  : { to: item.to })}
              >
                <div
                  style={item.to && isPath(item.to) ? activeStyle : undefined}
                >
                  <WidgetItem {...item} active={!!item.to && isPath(item.to)} />
                </div>
              </Link>
            ) : (
              <WidgetItem {...item} />
            )}
          </li>
        ))}
      </ul>

      <style jsx>{`
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          font-weight: 400;
        }
        li {
          user-select: none;
        }
        li:not(:first-child) {
          padding-top: 0.25em;
          border-top: 1px solid ${css.borderColor};
          transition: 200ms border-color;
        }
        li:not(:last-child) {
          /*padding-bottom: 0.25em;*/
        }
        div {
          display: block;
          text-decoration: none;
          color: inherit;
          border: 1px solid transparent;
          border-top-left-radius: 2em;
          border-bottom-left-radius: 2em;
        }
        button {
          font-weight: inherit;
          cursor: pointer;
          padding: 0;
          background-color: transparent;
          border: none;
          color: inherit;
          font-weight: inherit;
          width: calc(100% - 1em);
          border-radius: 0.3em;
        }
        div,
        button {
          padding: 0.1em 0.5em;
          transition: 200ms background-color, 200ms border-color;
        }
        div:hover,
        button:hover {
          background-color: ${css.hoverBackgroundColor};
        }
      `}</style>
    </Widget>
  )
}

export default WidgetList
