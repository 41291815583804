import {
  faPlus,
  faThumbsUp,
  faSearch,
  faTimesCircle,
  faBars,
  faEllipsisV,
  faSortAmountUp,
  faInfoCircle,
} from "@fortawesome/pro-regular-svg-icons"
import { faTimesSquare, faTimes } from "@fortawesome/pro-light-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ButterToast from "butter-toast"
import Link from "../link"
import React, {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
  ReactNode,
} from "react"

import { ToastContainer } from "react-toastify"
import { useFilters } from "../../hooks/use-filters"
import { useItems } from "../../hooks/use-items"
import { useStyle } from "../../hooks/use-style"
import { useUser } from "../../hooks/use-user"
import { prepareUrl } from "../../services/content"
import { darkTheme, lightTheme } from "../../services/theme"
import Logo from "../logo"
import UserPreview from "../user/preview"
import HtmlTooltip from "../widgets/html-tooltip"
import Etc from "../widgets/navbar/etc"
import MiddleLinks from "../widgets/navbar/middle-links"
import Subscriptions from "../widgets/navbar/subscriptions"
import TopLinks from "../widgets/navbar/top-links"

import { useSticky } from "../../hooks/use-sticky"
import Notice from "../widgets/notice"
import { Channel } from "../../types"
import Avatar from "./avatar"
import { useMedia } from "../../hooks/use-media"
import { isTouchDevice as isTabletMobile } from "../../services/mobile"
import { elementType } from "prop-types"
import { showToast } from "../status-notification"
import { useUpdate } from "../../hooks/use-update"
import { navigate } from "@reach/router"

type Props = {
  channel: Channel | undefined | null
  children: {
    content: ReactNode
    sidebar: ReactNode
  }
}

const ResponsiveLayout: FunctionComponent<Props> = ({ channel, children }) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { filters, updateFilters } = useFilters()
  const [text, setText] = useState(filters.q)
  const [newLink, setNewLink] = useState<string | null>(null)
  const [publisher, setPublisher] = useState<string | null>(null)
  const { likeNewItem } = useItems([])
  const { user, accountState, subscribe, isSubscribed } = useUser()
  const stickySidebar = useRef<HTMLDivElement>(null)
  const [showFooter, setShowFooter] = useState(false)
  const [showSubscribe, setShowSubscribe] = useState(false)
  const [footerAvatar, setFooterAvatar] = useState(false)
  const { numColumns, isBigScreen, isTallScreen, isAndroid } = useMedia()
  const { sidePanelClosed, setSidePanelClosed } = useUpdate()

  useSticky(stickySidebar, 60)

  const onFocus = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    event.currentTarget.placeholder = ""
    event.preventDefault()
  }, [])

  const onFocusOut = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    event.currentTarget.placeholder = "Search"
  }, [])

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.currentTarget.value.length == 0) {
        clear()
        return
      }
      let pos = event.currentTarget.value.indexOf("//")
      if (
        (pos == 5 || pos == 6) &&
        event.currentTarget.value.indexOf("http") == 0
      ) {
        let { preparedUrl, publisher } = prepareUrl(event.currentTarget.value)
        if (preparedUrl && publisher) {
          setNewLink(preparedUrl)
          setPublisher(publisher)
          updateFilters({ q: preparedUrl })
        }
      } else {
        setNewLink(null)
        setPublisher(null)
      }
      setText(event.currentTarget.value)
    },
    [filters]
  )

  const onSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault()
      updateFilters({ q: text, tags: [] })
    },
    [text, updateFilters]
  )

  const clear = useCallback(() => {
    setText("")
    updateFilters({ q: "" })
    if (newLink) {
      setNewLink(null)
      setPublisher(null)
    }

    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [newLink, updateFilters])

  useEffect(() => {
    setText(filters.q)
    if (numColumns < 3) {
      closeLeftPanel()
      closeRightPanel()
    }
  }, [filters])

  const onAddNewContent = useCallback(() => {
    if (newLink && publisher) {
      likeNewItem(newLink, publisher, 0)
      setNewLink(null)
      setPublisher(null)
      clear()
    } else {
      showToast(
        faInfoCircle,
        "",
        "To add new content, paste a link into the search field and hit the button." +
          (isAndroid ? ' Or add your favorite pages via "Share...".' : "")
      )
    }
  }, [newLink, publisher])

  const css = useStyle(theme => ({
    headerHeight: 60,
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    textColor:
      theme === "light"
        ? lightTheme.color.text.primary
        : darkTheme.color.text.primary,
    sidebarColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
    headerBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    footerTextColor:
      theme === "light"
        ? lightTheme.color.text.secondary
        : darkTheme.color.text.secondary,
    avatarColor:
      theme === "light"
        ? lightTheme.color.text.secondary
        : darkTheme.color.text.secondary,
    avatarBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    inputBackground:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    focusColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,

    actionButtonBackgroundColorUnset:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,

    actionButtonTextColor:
      theme === "light"
        ? lightTheme.color.text.primary
        : darkTheme.color.text.primary,
  }))

  let inSwipe = false
  let swipeStartX = -1
  let swipeStartY = -1
  let iOSBounceY = -1
  let swiped = false
  let showingPanel = 0

  const toggleLeftPanel = useCallback(() => {
    if (numColumns == 1) {
      if (showingPanel == -1) closeLeftPanel()
      else if (showingPanel == 1) {
        closeRightPanel()
        openLeftPanel()
      } else {
        openLeftPanel()
      }
    }
  }, [numColumns])

  const openLeftPanel = useCallback(() => {
    if (numColumns == 1) {
      setSidePanelClosed(false)
      let e = document.getElementById("left-panel")
      if (e) {
        //e.scroll(0, 0)
        showingPanel = -1
        e.classList.add("m-left-active")
        e = document.getElementById("main")
        if (e) e.classList.add("main-inactive")
      }
    }
  }, [numColumns])

  const closeLeftPanel = useCallback(() => {
    if (numColumns == 1) {
      showingPanel = 0
      let e = document.getElementById("left-panel")
      if (e && e.classList.contains("m-left-active")) {
        e.classList.remove("m-left-active")
        e = document.getElementById("main")
        if (e) e.classList.remove("main-inactive")
        return true
      } else return false
    } else return false
  }, [numColumns])

  const toggleRightPanel = () => {
    if (showingPanel == 1) closeRightPanel()
    else if (showingPanel == -1) {
      closeLeftPanel()
      openRightPanel()
    } else {
      openRightPanel()
    }
  }

  const openRightPanel = () => {
    setSidePanelClosed(false)
    let e = document.getElementById("right-panel")
    if (e) {
      showingPanel = 1
      e.scroll(0, 0)
      e.classList.add("m-right-active")
      e = document.getElementById("main")
      if (e) e.classList.add("main-inactive")
    }
  }

  const closeRightPanel = () => {
    showingPanel = 0
    let e = document.getElementById("right-panel")
    if (e && e.classList.contains("m-right-active")) {
      e.classList.remove("m-right-active")
      e = document.getElementById("main")
      if (e) e.classList.remove("main-inactive")
      return true
    } else return false
  }

  useEffect(() => {
    //console.log("sidePanelClosed " + sidePanelClosed)
    if (numColumns < 3 && sidePanelClosed) {
      closeRightPanel()
      closeLeftPanel()
    }
  }, [sidePanelClosed])

  useEffect(() => {
    if (numColumns < 3) {
      if (channel || (filters.tags && filters.tags.length > 0)) {
        if (numColumns == 2 && filters.tags && filters.tags.length == 0) {
          setShowFooter(false)
        } else {
          if (channel) {
            const avatar = channel.icon
              ? channel.icon
              : channel.image
              ? channel.image
              : null
            setFooterAvatar(avatar)
          }
          if (
            channel &&
            isSubscribed(channel.id) == false &&
            (!filters.tags || filters.tags.length == 0)
          )
            setShowSubscribe(true)
          else setShowSubscribe(false)
          setShowFooter(true)
        }
      } else setShowFooter(false)
    } else setShowFooter(false)
  }, [filters, channel, numColumns])

  const toggleSubscribe = useCallback(() => {
    setShowSubscribe(false)
    if (channel) subscribe(channel, null)
  }, [channel])

  let prevScrollpos = 0
  let isSearchBarVisible = true
  const handleScroll = () => {
    //console.log(window.pageYOffset + " " + prevScrollpos)
    var currentScrollPos = window.pageYOffset
    if (currentScrollPos > prevScrollpos && currentScrollPos > 40) {
      if (isSearchBarVisible) {
        isSearchBarVisible = false
        let e = document.getElementById("navbar")
        if (e) e.style.top = "-50px"
      }
    } else {
      if (isSearchBarVisible == false) {
        isSearchBarVisible = true
        let e = document.getElementById("navbar")
        if (e) e.style.top = "0"
      }
    }

    prevScrollpos = currentScrollPos
  }

  useEffect(() => {
    if (numColumns < 3 && isTallScreen == false) {
      document.addEventListener("scroll", handleScroll)
      return () => {
        document.removeEventListener("scroll", handleScroll)
      }
    }
  }, [handleScroll, isTallScreen])

  const handleSwiperTouchStart = event => {
    //console.log("swiper got " + event.type)
    inSwipe = false
    swipeStartX = event.touches[0].clientX
    swipeStartY = event.touches[0].clientY
    iOSBounceY = swipeStartY
    swiped = false
    if (showingPanel != 0) {
      const el = document.getElementById(
        showingPanel == -1 ? "left-panel" : "right-panel"
      )
      if (el) {
        if (el.contains(event.target) == false) {
          //console.log("clicked outside menu")
          let el = document.getElementById("filter-footer")
          if (el) {
            console.log(event.target)
            if (el.contains(event.target) == false) {
              event.preventDefault()
              //console.log("inside footer")
            } else {
              //console.log("outside footer")
            }
          }
        } else {
          //console.log("clicked menu")
        }
      }
    }
  }

  const handleSwiperTouchEnd = event => {
    if (!inSwipe) {
      let el = document.elementFromPoint(
        event.changedTouches[0].clientX,
        event.changedTouches[0].clientY
      )
      if (el) {
        //console.log("found element")
        const frames = el.getElementsByClassName("embedded-frame")
        if (frames && frames.length) {
          //console.log("inside frame")
          frames[0].classList.remove("embedded-frame-silent")
        }
      }
    }
    if (!swiped && showingPanel != 0) {
      const el = document.getElementById(
        showingPanel == -1 ? "left-panel" : "right-panel"
      )
      if (el) {
        if (el.contains(event.target) == false) {
          console.log("clicked outside menu")
          closeLeftPanel()
          closeRightPanel()

          let el = document.getElementById("filter-footer")
          if (el) {
            console.log(event.target)
            if (el.contains(event.target) == false) {
              event.preventDefault()
              console.log("inside footer")
            } else {
              console.log("outside footer")
            }
          }
        } else console.log("clicked menu")
      }
    }

    inSwipe = false
    swipeStartX = -1
    iOSBounceY = -1
    swiped = false
  }

  const handleSwiperTouchMove = useCallback(
    event => {
      const x = event.changedTouches[0].clientX
      const y = event.changedTouches[0].clientY
      //console.log("x = " + x + " y = " + y)
      const yThreshold = 100 //20
      const xThreshold = 40 //30

      if (!swiped && y - swipeStartY < 20 && swipeStartY - y < yThreshold) {
        if (x - swipeStartX > xThreshold) {
          swiped = true
          //console.log("swiped right")

          if (!closeRightPanel()) {
            if (numColumns == 1) {
              openLeftPanel()
            }
          }
        }
        if (swipeStartX - x > xThreshold) {
          swiped = true
          //console.log("swiped left")

          if (!closeLeftPanel()) {
            openRightPanel()
          }
        }
      }

      inSwipe = true
    },
    [numColumns]
  )

  const handleSwiperTouchCancel = event => {
    //console.log("swiper got " + event.type)
    inSwipe = false
    swipeStartX = -1
    iOSBounceY = -1
    swiped = false
  }

  useEffect(() => {
    if (numColumns < 3) {
      var el = document.getElementById("root1")
      if (el) {
        el.addEventListener("touchstart", handleSwiperTouchStart, false)
        el.addEventListener("touchend", handleSwiperTouchEnd, false)
        el.addEventListener("touchcancel", handleSwiperTouchCancel, false)
        el.addEventListener("touchmove", handleSwiperTouchMove, false)
      }
      var el = document.getElementById("swiper")
      if (el) {
        el.addEventListener("touchstart", handleSwiperTouchStart, false)
        el.addEventListener("touchend", handleSwiperTouchEnd, false)
        el.addEventListener("touchcancel", handleSwiperTouchCancel, false)
        el.addEventListener("touchmove", handleSwiperTouchMove, false)
      }
      return () => {
        var el = document.getElementById("root1")
        if (el) {
          el.removeEventListener("touchstart", handleSwiperTouchStart)
          el.removeEventListener("touchend", handleSwiperTouchEnd)
          el.removeEventListener("touchcancel", handleSwiperTouchCancel)
          el.removeEventListener("touchmove", handleSwiperTouchMove)
        }
        var el = document.getElementById("swiper")
        if (el) {
          el.removeEventListener("touchstart", handleSwiperTouchStart)
          el.removeEventListener("touchend", handleSwiperTouchEnd)
          el.removeEventListener("touchcancel", handleSwiperTouchCancel)
          el.removeEventListener("touchmove", handleSwiperTouchMove)
        }
      }
    }
  }, [numColumns])

  const resetFilters = useCallback(() => {
    setShowFooter(false)
    updateFilters({ channel_ids: [], tags: [] })
  }, [updateFilters])

  const removeTag = useCallback(
    async (tag: string) => {
      if (filters && filters.tags) {
        let idx = filters.tags.indexOf(tag)
        if (idx != -1) {
          let tags = filters.tags.slice()
          tags?.splice(idx, 1)
          updateFilters({ tags: tags })
        }
      }
    },
    [filters.tags]
  )

  return (
    <>
      <div className="swiper" id="swiper" />
      <div className="layout" id="root1">
        {numColumns == 1 ? (
          <header id="navbar" className="m-header-navbar">
            <div
              className="m-header"
              style={{ display: "flex", maxWidth: "640px" }}
            >
              <div className="side-menu" onClick={toggleLeftPanel}>
                <FontAwesomeIcon
                  icon={faBars}
                  style={{ height: "18px", width: "18px" }}
                />
              </div>

              <div className="search-1">
                <form className="input" onSubmit={onSubmit}>
                  <input
                    type="text"
                    placeholder="Search"
                    value={text}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onFocusOut}
                  />
                  {text && (
                    <button className="clear" onClick={clear} type="button">
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </button>
                  )}
                  {numColumns > 1 && (
                    <button className="submit">
                      <FontAwesomeIcon icon={faSearch} />
                    </button>
                  )}
                </form>

                {accountState > 0 && (
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: 500,
                            marginBottom: "10px",
                          }}
                        >
                          Smartlike new content
                        </div>
                        <div style={{ fontSize: "12px", fontWeight: 400 }}>
                          <p>
                            Paste a link into the search field and click the
                            button.
                          </p>
                          {isAndroid && (
                            <p>
                              You can also <b>share</b> a page from your
                              browser.
                            </p>
                          )}
                        </div>
                      </React.Fragment>
                    }
                    placement="top"
                    disableTouchListener={newLink != null}
                    interactive
                  >
                    <div
                      className={newLink ? "add-content active" : "add-content"}
                      onClick={onAddNewContent}
                    >
                      <FontAwesomeIcon
                        icon={faThumbsUp}
                        style={{ height: "18px", width: "18px" }}
                      />
                    </div>
                  </HtmlTooltip>
                )}
              </div>
              <div className="side-menu" onClick={toggleRightPanel}>
                <FontAwesomeIcon
                  icon={faSortAmountUp}
                  style={{ height: "18px", width: "18px" }}
                />
              </div>
            </div>
          </header>
        ) : (
          <>
            {numColumns == 2 ? (
              <header id="navbar" className="m-header-navbar">
                <div className="m-header-2">
                  <div className="logo" style={{ textAlign: "center" }}>
                    {/*
                    <Link to="/">
                      <Logo />
                    </Link>
                    */}
                  </div>

                  <div className="search">
                    <form className="input" onSubmit={onSubmit}>
                      <input
                        type="text"
                        placeholder="Search"
                        value={text}
                        onChange={onChange}
                        onFocus={onFocus}
                        onBlur={onFocusOut}
                      />
                      {text && (
                        <button className="clear" onClick={clear} type="button">
                          <FontAwesomeIcon icon={faTimesCircle} />
                        </button>
                      )}
                      <button className="submit">
                        <FontAwesomeIcon icon={faSearch} />
                      </button>
                    </form>

                    {accountState > 0 && (
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <div
                              style={{
                                fontSize: "14px",
                                fontWeight: 500,
                                marginBottom: "10px",
                              }}
                            >
                              Smartlike new content
                            </div>
                            <div style={{ fontSize: "12px", fontWeight: 400 }}>
                              <p>
                                Paste a link into the search field and click the
                                button.
                              </p>
                              {isAndroid && (
                                <p>
                                  You can also <b>share</b> a page from your
                                  browser.
                                </p>
                              )}
                            </div>
                          </React.Fragment>
                        }
                        placement="top"
                        interactive
                      >
                        <div
                          className={
                            newLink ? "add-content active" : "add-content"
                          }
                          onClick={onAddNewContent}
                        >
                          <FontAwesomeIcon
                            icon={faThumbsUp}
                            style={{ height: "18px", width: "18px" }}
                          />
                        </div>
                      </HtmlTooltip>
                    )}
                  </div>
                </div>
              </header>
            ) : (
              <header id="navbar" className="header-navbar">
                <div
                  className="header row"
                  style={isBigScreen ? { gridColumnGap: "2rem" } : {}}
                >
                  <div className="logo">
                    <div
                      className="poor-mans-logo"
                      onClick={() => {
                        navigate("/")
                      }}
                    >
                      smartlike
                      <img
                        src="/favicons/ukraine.svg"
                        style={{ height: "20px", marginLeft: "5px" }}
                      />
                    </div>
                  </div>
                  <div className="search">
                    <form className="input" onSubmit={onSubmit}>
                      <input
                        type="text"
                        placeholder="Search"
                        value={text}
                        onChange={onChange}
                        onFocus={onFocus}
                        onBlur={onFocusOut}
                      />

                      {text && (
                        <button className="clear" onClick={clear} type="button">
                          <FontAwesomeIcon icon={faTimesCircle} />
                        </button>
                      )}

                      <button className="submit">
                        <FontAwesomeIcon icon={faSearch} />
                      </button>
                    </form>

                    {accountState > 0 && (
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <div
                              style={{
                                fontSize: "14px",
                                fontWeight: 500,
                                marginBottom: "10px",
                              }}
                            >
                              Smartlike new content
                            </div>
                            <div style={{ fontSize: "12px", fontWeight: 400 }}>
                              <p>
                                Paste a link into the search field and click the
                                button.
                              </p>

                              <p>
                                You may also wish to install{" "}
                                <a
                                  href="https://chrome.google.com/webstore/detail/smartlike/hbeaghhbggdilbedobkfhneaajmnfipc"
                                  target="_blank"
                                >
                                  browser extension
                                </a>{" "}
                                to do it without visiting smartlike.org
                              </p>
                            </div>
                          </React.Fragment>
                        }
                        placement="top"
                        interactive
                      >
                        <div
                          className={
                            newLink ? "add-content active" : "add-content"
                          }
                          onClick={onAddNewContent}
                        >
                          <FontAwesomeIcon
                            icon={faThumbsUp}
                            style={{ height: "18px", width: "18px" }}
                          />
                        </div>
                      </HtmlTooltip>
                    )}
                  </div>
                  <div className="user">
                    <UserPreview />
                  </div>
                </div>
              </header>
            )}
          </>
        )}

        <div
          className={numColumns < 3 ? "m-content-wrapper" : "content-wrapper"}
        >
          <div
            className={numColumns == 1 ? "m-content" : "left-content row"}
            style={isBigScreen ? { gridColumnGap: "2rem" } : {}}
          >
            <div
              className={numColumns == 1 ? "m-left" : ""}
              id={numColumns == 1 ? "left-panel" : ""}
            >
              <nav
                className={
                  numColumns == 1
                    ? "navbar"
                    : numColumns == 2
                    ? "navbar navbar-2 navbar-sticky"
                    : "navbar navbar-bigscreen navbar-sticky"
                }
                style={{ zIndex: 1000, overflowY: "auto" }}
              >
                <div>
                  {numColumns == 1 && (
                    <div style={{ display: "table", width: "100%" }}>
                      <div
                        className="poor-mans-logo"
                        style={{ display: "table-cell" }}
                        onClick={() => {
                          navigate("/")
                        }}
                      >
                        smartlike
                      </div>
                      <div
                        onClick={closeLeftPanel}
                        style={{
                          textAlign: "right",
                          verticalAlign: "middle",
                          display: "table-cell",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          style={{
                            textAlign: "right",
                            fontSize: "16px",
                            margin: "5px",
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <div>
                    <TopLinks />
                  </div>
                </div>

                <div className="subscriptions">
                  <div>
                    <Subscriptions />
                  </div>
                </div>

                {accountState > 0 && (
                  <div>
                    <div>
                      <MiddleLinks />
                    </div>
                  </div>
                )}

                <div>
                  <div>
                    <Etc />
                  </div>
                </div>

                <div className="navbar-footer">
                  <ul>
                    {user && user.id && user.id.length > 0 && (
                      <li key="log">
                        <Link to="/log">
                          <a>Log</a> &bull;
                        </Link>
                      </li>
                    )}
                    <li key="audit">
                      <Link to="/audit">
                        <a>Data</a>
                      </Link>{" "}
                      &bull;
                    </li>
                    <li key="rates" style={{ whiteSpace: "nowrap" }}>
                      <Link to="/rates">
                        <a>Exchange rates</a>
                      </Link>{" "}
                      &bull;
                    </li>
                    <li key="forum">
                      <a
                        href="https://github.com/smartlike-org/smartlike/discussions"
                        target="_blank"
                      >
                        Forum
                      </a>{" "}
                      &bull;
                    </li>
                    <li key="github">
                      <a
                        href="https://github.com/smartlike-org"
                        target="_blank"
                      >
                        GitHub
                      </a>{" "}
                      &bull;
                    </li>
                    <li key="blog">
                      <Link to="/channel/4Dd0lJMg4TT3IAFb5XBThQa6zoYs0UTMDw4cat">
                        <a>Blog</a>
                      </Link>{" "}
                      &bull;
                    </li>
                    <li key="privacy">
                      <Link to="/docs/privacy">
                        <a>Privacy</a>
                      </Link>{" "}
                      &bull;
                    </li>
                    <li key="terms">
                      <Link to="/docs/terms">
                        <a>Terms</a>
                      </Link>{" "}
                      &bull;
                    </li>
                    <li key="kelp">
                      <Link to="/docs">
                        <a>Docs</a>
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>

            <div>
              <div>
                {numColumns < 3 ? (
                  <>
                    <div className="m-content">
                      {/*
                      <Notice id="swipe_notice" submit="Got it" compact={true}>
                        Swipe right for subscriptions.
                        <br />
                        Swipe left for filters.
                      </Notice>
                      */}

                      <div className="m-main" id="main">
                        {children.content}
                      </div>
                    </div>

                    {showFooter && (
                      <div id="filter-footer" className="filter-footer">
                        <div style={{ display: "table-row" }}>
                          {footerAvatar && (
                            <div
                              style={{
                                display: "table-cell",
                                verticalAlign: "middle",
                                width: "35px",
                              }}
                            >
                              <Avatar
                                image={footerAvatar || undefined}
                                label={channel?.label}
                                size={30}
                              />
                            </div>
                          )}

                          <div className="tags">
                            {filters &&
                              filters.tags &&
                              filters.tags.length > 0 &&
                              filters.tags.map(t => (
                                <div
                                  key={t}
                                  className="tag"
                                  onClick={() => removeTag(t)}
                                >
                                  {t}
                                  <FontAwesomeIcon
                                    icon={faTimes}
                                    style={{
                                      paddingLeft: "4px",
                                      verticalAlign: "middle",
                                      fontSize: "14px",
                                    }}
                                  />
                                </div>
                              ))}
                          </div>

                          {showSubscribe && (
                            <div
                              style={{
                                verticalAlign: "middle",
                                display: "table-cell",
                              }}
                            >
                              <button
                                className="subscribe-button"
                                onClick={toggleSubscribe}
                              >
                                <div style={{ display: "inline-flex" }}>
                                  Subscribe
                                </div>
                              </button>
                            </div>
                          )}

                          <div className="highlighted" onClick={resetFilters}>
                            <FontAwesomeIcon icon={faTimes} />
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="content">
                    <main>{children.content}</main>
                    <div>
                      <aside ref={stickySidebar}>{children.sidebar}</aside>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <ToastContainer />
            <ButterToast
              position={{
                horizontal: numColumns == 1 ? "POS_CENTER" : "POS_RIGHT",
                vertical: "POS_BOTTOM",
              }}
            />
          </div>
        </div>

        {numColumns < 3 && children.sidebar && (
          <>
            <div style={{ overflow: "hidden" }}>
              <div className="m-right" id="right-panel">
                <div onClick={closeRightPanel} style={{ textAlign: "right" }}>
                  <FontAwesomeIcon
                    icon={faTimes}
                    style={{
                      textAlign: "right",
                      fontSize: "16px",
                      margin: "15px",
                      marginBottom: "0px",
                    }}
                  />
                </div>

                <div className="user">
                  <UserPreview />
                </div>

                {children.sidebar}
              </div>
            </div>
          </>
        )}
      </div>

      <style jsx>{`
        .poor-mans-logo {
          font-family: "Roboto";
          font-weight: 400;
          padding-left: 20px;
          font-size: 28px;
        }
        .poor-mans-logo:hover {
          cursor: pointer;
        }
        .subscribe-button {
          border-radius: 0.3em;
          min-width: 8em;
          height: 1.8em;
          border: none;
          color: ${css.actionButtonTextColor};
          background-color: ${css.actionButtonBackgroundColorUnset};
          transition: 200ms background-color, 200ms text-color;
          width: 90px;
          margin-right: 15px;
          margin-left: 5px;
          float: right;
        }

        .tags {
          padding: 5px;
          /*border-top: 1px solid ${css.borderColor};*/
          flex-wrap: wrap;
          display: inline-flex;
        }
        .tag {
          background-color: rgb(105, 105, 105, 0.1);
          padding: 5px;
          margin-right: 5px;
          margin-bottom: 5px;
          border-radius: 5px;
          font-size: 14px;
          word-break: keep-all;
          user-select: none;
        }
        .tag:hover {
          background-color: rgb(105, 105, 105, 0.3);
          cursor: pointer;
        }

        .highlighted {
          font-size: 28px;
          opacity: 0.7;
          display: table-cell;
          vertical-align: middle;
          width: 30px;
          /*
          text-align: center;
          color: #39454a;

          border-radius: 50%;
          width: 22px;
          height: 22px;
          background-color: rgb(255, 213, 79);
          line-height: 24px;
          margin: auto;*/
        }

        .swiper {
          position: fixed;
          width: 100%;
          height: 100%;
          background-color: transparent;
          /*background-color: #ff0000;
          opacity: 0.3;*/
          z-index: -10000;
        }
        .m-left-active {
          transition: 0.2s !important;
          left: 0 !important;
        }

        .m-left {
          background-color: white;
          position: fixed;
          z-index: 99998;
          top: 0;
          bottom: 0;
          left: -200px;
          width: 200px;
          transition: 0.2s;
          overflow-x: hidden;
        }

        .m-right-active {
          transition: 0.2s !important;
          right: 0 !important;
        }

        .m-right {
          background-color: white;
          position: fixed;
          z-index: 1000;
          top: 0;
          bottom: 0;
          right: -340px;
          width: 340px;
          transition: 0.2s;
          overflow-x: hidden;
        }

        .m-content {
          width: 100%;
        }
        .content {
          display: grid;
          grid-template-columns: minmax(0, 640px) minmax(340px, 3fr);
          grid-gap: 2rem;
        }
        main {
          padding-top: 1.5em;
          padding-bottom: 1.5em;
          width: 640px;
        }
        .m-main {
          padding-bottom: 1.5em;
          max-width: 640px;
          width: 100%;
          transition: 0.2s;
        }

        .main-inactive {
          opacity: 0.5;
          transition: 0.5s;
        }
        aside {
          position: sticky;
          padding-top: 1.5em;
          padding-bottom: 1.5em;
        }
        aside > :global(*:not(:first-child)) {
          margin-top: 1.5em;
        }

        .left-menu {
        }
        .active {
          background-color: ${css.focusColor} !important;
        }
        form {
          flex: 1;
          border-radius: 2em;
          background-color: ${css.inputBackground};
          border: 1px solid ${css.borderColor};
          transition: 200ms background-color, 200ms border-color,
            200ms box-shadow;
          display: flex;
          position: relative;
        }
        form:focus-within {
          box-shadow: 0 0 0 1px ${css.focusColor};
        }
        input {
          width: 100%;
          height: 100%;
          margin: auto;
          background-color: transparent;
          color: inherit;
          border: none;
          padding-left: 1em;
          padding-right: 0.5em;
          flex: 1;
          border-top-left-radius: 0.3em;
          border-bottom-left-radius: 0.3em;
          font: inherit;
          outline: none;
        }
        button {
          border: none;
          cursor: pointer;
          background-color: transparent;
          color: inherit;
        }
        .clear {
          align-self: center;
          padding: 0;
          font-size: 17px;
          color: rgb(129, 129, 129);
        }
        .submit {
          padding-left: 0.6em;
          padding-right: 1em;
          color: rgb(129, 129, 129);
        }
        .layout {
          /* min-width: 1240px; */
          color: ${css.textColor};
          transition: 200ms color;
          display: flex;
          flex-direction: column;
        }
        .row {
          max-width: 1240px;
          width: 100%;
          margin: auto;
          display: grid;
          /*grid-column-gap: 2rem;*/
          align-items: flex-start;
        }
        .header-navbar {
          height: 60px;
          position: sticky;
          width: 100%;
          background-color: ${css.headerBackgroundColor};
          display: flex;
          align-items: center;
          top: 0;
          z-index: 4;
          border-bottom: 1px solid ${css.borderColor};
          transition: 200ms background-color, 200ms border-color;
        }
        .m-header-navbar {
          height: 50px;
          position: fixed;
          width: 100%;
          background-color: ${css.headerBackgroundColor};
          display: flex;
          align-items: center;
          top: 0;
          z-index: 4;
          border-bottom: 1px solid ${css.borderColor};
          transition: 200ms background-color, 200ms border-color;
        }
        .header {
          padding: 0 20px;
          max-width: 1280px;
          align-items: center;
          grid-template-columns: minmax(0, 2fr) minmax(0, 7fr) minmax(0, 3fr);
        }
        .m-header {
          padding: 0 5px;
          width: 100%;
        }
        .m-header-2 {
          padding: 0 5px;
          width: 100%;
          grid-template-columns: minmax(200px, 2fr) minmax(0px, 10fr);
          display: grid;
          -webkit-box-align: center;
          align-items: center;
        }

        .logo {
          /*height: 22px;*/
        }
        .logo a {
          text-decoration: none;
        }
        .m-content-wrapper {
          padding-top: 55px;
        }

        .content-wrapper {
          flex: 1;
          display: flex;
        }
        .content-wrapper::before,
        .content-wrapper::after {
          content: "";
          align-self: stretch;
          min-width: 0px;
          flex: 1;
        }
        .content-wrapper::before {
          background-color: ${css.sidebarColor};
          transition: 200ms background-color;
        }
        .content-wrapper::after {
          background-color: ${css.backgroundColor};
          transition: 200ms background-color;
        }
        .left-content {
          grid-template-columns: minmax(200px, 2fr) minmax(0, 10fr);
          align-items: stretch;
          z-index: 0;
        }
        .m-content {
          width: 100%;
          z-index: 0;
        }
        .navbar-footer {
          color: ${css.footerTextColor};
          transition: 200ms color;
          font-size: 13px;
          padding: 1em 0;
          margin-bottom: 40px;
          /*margin-left: 10px;*/
        }
        .navbar-footer ul {
          /*display: flex;*/
          width: 100%;
          align-items: center;
          list-style: none;
          margin: 0;
          padding-left: 1em;
        }
        .navbar-footer li {
          margin-right: 0.5em;
          line-height: 18px;
          float: left;
        }
        /*.navbar-footer li:not(:first-child) {
          margin-left: 0.5em;
        }
        .navbar-footer li:not(:last-child) {
          margin-right: 0.5em;
        }*/
        .navbar-footer a {
          text-decoration: none;
          color: inherit;
        }
        .navbar-footer a:hover {
          text-decoration: underline;
        }

        .navbar {
          padding-left: 0.5em;
          margin-left: -0.5em;
          background-color: ${css.sidebarColor};
          transition: 400ms background-color;
          display: flex;
          flex-direction: column;
        }
        .navbar:hover {
          overflow-y: auto;
        }
        .navbar > *:not(:first-child) {
          border-top: 1px solid ${css.borderColor};
          transition: 200ms border-color;
        }
        .navbar > * {
          margin-right: 1em;
          position: relative;
        }
        .navbar > *:first-child {
          padding-top: 0.75em;
        }
        .navbar > *::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          right: -1em;
          z-index: -1;
          width: 0;
          border-right: 1px solid ${css.borderColor};
          transition: 200ms border-color;
        }
        .navbar > * > * {
          margin-right: -1em;
        }

        .navbar-sticky {
          position: sticky;
          overflow-y: hidden;
        }

        .navbar-bigscreen {
          top: 60px;
          height: calc(100vh - 60px);
        }

        .navbar-mobile {
          top: 0px;
          height: calc(100vh);
          padding-top: 50px;
        }
        .navbar-2 {
          top: 0px;
          height: calc(100vh);
        }

        .filter-footer {
          height: 36px;
          position: fixed;
          left: 0;
          bottom: 0;
          width: 100%;
          background-color: ${css.sidebarColor};
          transition: 200ms background-color;
          display: table;
          /*z-index: 10000000;*/
          z-index: 10000;
          padding-left: 5px;
          padding-right: 5px;
          border-top: 1px solid ${css.borderColor};
        }

        .subscriptions {
          flex: 1;
        }
        .search {
          display: flex;
          height: 40px;
          max-width: 640px;
          width: 100%;
          font-family: Roboto;
        }
        .search-1 {
          display: flex;
          height: 40px;
          width: 100%;
          font-family: Roboto;
        }
        .add-content {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: ${css.avatarBackgroundColor};
          color: ${css.avatarColor};
          transition: 200ms background-color, 200ms color;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 5px;
          border: 1px solid ${css.borderColor};
        }
        .add-content:hover {
          cursor: pointer;
        }

        .side-menu {
          width: 50px;
          color: ${css.avatarColor};
          transition: 200ms background-color, 200ms color;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .user {
          display: flex;
          justify-content: flex-end;
        }
      `}</style>
    </>
  )
}

export default ResponsiveLayout
