import { Tooltip, makeStyles } from "@material-ui/core"
import React from "react"
import { useMedia } from "../../hooks/use-media"

/*
const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "wheat",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 250,
    padding: 15,
    fontSize: 12,
  },
}))(Tooltip)
*/

const useStylesBootstrap = makeStyles(theme => ({
  tooltip: {
    /*backgroundColor: "#ffe180",*/
    backgroundColor: "wheat",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 250,
    padding: 15,
    fontSize: 12,
    /*border: "1px solid #dadde9",*/
  },
}))

function HtmlTooltip(props) {
  const classes = useStylesBootstrap()
  const { isTouchDevice } = useMedia()

  return (
    <Tooltip
      arrow
      classes={classes}
      {...props}
      enterTouchDelay={1}
      leaveTouchDelay={3000}
      disableTouchListener={isTouchDevice}
    />
  )
}

export default HtmlTooltip
